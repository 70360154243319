import richText from "utilities/richText"
import cn from "classnames"

export default function FeaturedList({ blok }) {
  return (
    <div
      className={cn(
        "featured-list prose:list-disc-none prose mb-6 mt-4 rounded-xl p-6 shadow prose-h4:mt-0 prose-ul:mt-5 prose-li:pb-[2px] prose-li:font-bold",
        blok.background_color && `bg-${blok.background_color}`,
        { "bg-white": blok.background_color === "" && blok.background_color === undefined },
      )}
    >
      {richText(blok.main_content)}
    </div>
  )
}
